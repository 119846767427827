import { fetchUsers } from "../../../services/userApis/userApis";
import { fetchAllUserAttendance } from "../../../services/attendenceApis/attendence";
import { useEffect, useState } from "react";
import { User, AttendanceData } from "../../types";
import { CloseOutlined } from "@ant-design/icons";
import {
  Avatar,
  Card,
  Col,
  Layout,
  Progress,
  Row,
  Typography,
  Modal,
} from "antd";
import { LineChart, PieChart } from "@mui/x-charts";
import { BarChart } from "@mui/x-charts";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import "./Dashboard.css";
import { fetchAllLeaveApplications } from "../../../services/leaveApplication/leaveApplication";
import { UserData } from "../../types";
import { fetchUserData } from "../../../services/userApis/userApis";
const { Title } = Typography;

interface ApiResponse {
  success: boolean;
  data: ApiData[];
}

interface ApiData {
  _id: string;
  user: string;
  userName: string;
  time_in: string;
  date: string;
  status: string;
  __v: number;
  time_out?: string;
  working_hours?: number;
}

export const AppContent = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [attendanceData, setAttendanceData] = useState<AttendanceData[]>([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [presentEmployees, setPresentEmployees] = useState(0);
  const [absentEmployees, setAbsentEmployees] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [leaveApplicationData, setLeaveApplicationData] = useState([]);
  const [totalLeaves, setTotalLeaves] = useState(0);
  const [apiResponse, setApiResponse] = useState<ApiResponse>({
    success: false,
    data: [],
  });
  const [userData, setUserData] = useState<UserData | null>(null);
  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const handleCancel = () => setVisible(false);

  const PerLeave = Math.trunc((totalLeaves / totalEmployees) * 100) || 0;
  const PerEmpl = Math.trunc((presentEmployees / totalEmployees) * 100) || 0;
  const PerAbse = Math.trunc((absentEmployees / totalEmployees) * 100) || 0;
  const PerTotalEmp = Math.trunc((totalEmployees / totalEmployees) * 100) || 0;

  useEffect(() => {
    const fetchData = async () => {
      const userDetail = await fetchUserData();
      setUserData(userDetail);
    };
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const filters = {};
        const response = await fetchAllUserAttendance(filters);
        setApiResponse({ success: true, data: response.data });
      } catch (error) {
        console.error("Error fetching attendance data:", error);
        setApiResponse({ success: false, data: [] });
      }
    }
    fetchData();
  }, []);

  const calculateAttendance = () => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const presentCounts = [];
    const absentCounts = [];
    const xLabels = [];

    let currentDate = new Date(sevenDaysAgo);
    while (currentDate <= today) {
      const dateString = currentDate.toISOString().split("T")[0];
      const presentEmployees = apiResponse.data.filter((entry) => {
        const entryDate = new Date(entry.date); // Convert MongoDB ISODate string to Date object
        const entryDateString = entryDate.toISOString().split("T")[0];
        return entryDateString === dateString && entry.status === "Present";
      });

      const absentEmployees = apiResponse.data.filter((entry) => {
        const entryDate = new Date(entry.date); // Convert MongoDB ISODate string to Date object
        const entryDateString = entryDate.toISOString().split("T")[0];
        return entryDateString === dateString && entry.status === "Absent";
      });

      presentCounts.push(presentEmployees.length);
      absentCounts.push(absentEmployees.length);
      xLabels.push({ label: dateString });

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
      presentCounts,
      absentCounts,
      xLabels,
    };
  };

  const { presentCounts, absentCounts, xLabels } = calculateAttendance();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetchAllUserAttendance({});
        const usersData = await fetchUsers();
        setUsers(usersData);
        setAttendanceData(response.data);
      } catch (error) {
        console.error("Error fetching user attendance:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await fetchAllLeaveApplications();
        if (response && response.content) {
          const today = new Date().toISOString().split("T")[0];

          const todayLeaveApplications = response.content.filter(
            (application: any) => {
              const applicationDate = new Date(application.date)
                .toISOString()
                .split("T")[0];
              return applicationDate === today;
            }
          );

          setTotalLeaves(todayLeaveApplications.length);
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        console.error("Error fetching leave applications data:", error);
        setError("Failed to fetch leave applications. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const usersData = await fetchUsers();
        setUsers(usersData);
        const userWithUserRole = usersData.filter(
          (user) => user.jobDetail.role === "user"
        );
        setTotalEmployees(userWithUserRole.length);

        const today = new Date().toISOString().slice(0, 10);
        const response = await fetchAllUserAttendance({
          startDate: today,
          endDate: today,
        });
        const presentEmployees = response.data.filter(
          (record: AttendanceData) => record.status === "Present"
        ).length;
        const absentEmployees = response.data.filter(
          (record: AttendanceData) => record.status === "Absent"
        ).length;
        setAttendanceData(response.data);
        setPresentEmployees(presentEmployees);

        setAbsentEmployees(absentEmployees);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div>
        <div className=" bg-slate-200 p-1 rounded-md">
          <div className="border-l-4 border-secondary-color flex justify-between h-15 mr-10">
            <Title level={5} className="ml-2 mt-4">
              Dashboard
            </Title>
            <div className="flex items-center">
              <Avatar
                style={{ width: "65px", height: "65px", cursor: "pointer" }}
                className="hover:scale-110 transition duration-150"
                onClick={showModal}
              >
                <img
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                  src={userData?.userDetail.profileImage}
                  alt="User Avatar"
                />
              </Avatar>
              <Typography className="font-bold ml-2">
                {userData?.userDetail.fullName}
              </Typography>
            </div>
            <Modal
              visible={visible}
              onCancel={handleCancel}
              footer={null}
              width={400}
              style={{ top: "40%", transform: "translateY(-50%)" }}
              closeIcon={<CloseOutlined />} // Custom close icon
            >
              <div className="modal-content" style={{ textAlign: "center" }}>
                <img
                  src={userData?.userDetail.profileImage}
                  alt="User Avatar"
                  style={{
                    width: "300px",
                    height: "300px",
                    objectFit: "cover",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              </div>
            </Modal>
          </div>
        </div>
        <div className="mx-10 my-8 flex justify-between">
          <div>
            <Typography className="font-bold">Today's Attendance</Typography>
            <Typography className="text-slate-400">
              {new Date().toLocaleDateString()}
            </Typography>
          </div>
        </div>
        <Layout className="bg-white flex !justify-center">
          <div className="grid grid-cols-4 gap-5 px-5">
            <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition duration-150">
                <div className="flex justify-between items-center px-5 py-2">
                  <div>
                    <PeopleAltIcon fontSize="large" />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerTotalEmp}
                      strokeWidth={10}
                      strokeColor="rgb(2,132,199)"
                      trailColor="red"
                      format={() => `${PerTotalEmp}%`}
                      size={60}
                    />
                  </div>
                </div>
                <div className="pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Total Employees
                  </div>
                  <div className="text-3xl font semibold">
                    {" "}
                    {totalEmployees || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition duration-150">
                <div className="flex justify-between items-center px-5 py-2">
                  <div>
                    <ContactMailIcon fontSize="large" />
                  </div>
                  <div className="roundprogress">
                    <Progress
                      type="circle"
                      percent={PerLeave}
                      strokeWidth={10}
                      strokeColor="rgb(2,132,199)"
                      trailColor="rgb(29, 198, 128)"
                      format={() => `${PerLeave}%`}
                      size={60}
                    />
                  </div>
                </div>
                <div className="pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Today Leave's
                  </div>
                  <div className="text-3xl font semibold">
                    {" "}
                    {totalLeaves || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition duration-150">
                <div className="flex justify-between items-center px-5 py-2">
                  <div>
                    <PersonAddIcon fontSize="large" />
                  </div>
                  <div className=" roundprogress ">
                    <Progress
                      type="circle"
                      percent={PerEmpl}
                      strokeWidth={10}
                      strokeColor="rgb(2, 178, 175)"
                      trailColor="rgb(46, 150, 255)"
                      format={() => <span>{`${PerEmpl}%`}</span>}
                      size={60}
                    />
                  </div>
                </div>
                <div className="pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Present Employee's
                  </div>
                  <div className="text-3xl font semibold">
                    {presentEmployees || 0}
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:col-span-1 sm:col-span-4 xs:col-span-4">
              <div className="bg-slate-200 rounded-xl shadow-lg hover:scale-105 transition duration-150">
                <div className="flex justify-between items-center px-5 py-2">
                  <div>
                    <PersonRemoveIcon fontSize="large" />
                  </div>
                  <div className=" roundprogress">
                    <Progress
                      type="circle"
                      percent={PerAbse}
                      strokeWidth={10}
                      strokeColor="rgb(46, 150, 255)"
                      trailColor="rgb(2, 178, 175)"
                      format={() => `${PerAbse}%`}
                      size={60}
                    />
                  </div>
                </div>
                <div className="pl-7 pb-2">
                  <div className="text-blue-600 font-semibold">
                    Absent Employee's
                  </div>
                  <div className="text-3xl font semibold">
                    {absentEmployees || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
        <div className="md:flex-row md:justify-between pl-6 pr-6 w-full flex flex-col mb-10 ">
          <div className="md:w-1/2 w-full mb-6 md:mb-0 ">
            <Card
              className="shadow-lg"
              title="Weekly Employees Status"
              style={{
                marginTop: "50px",
                textAlign: "center",
              }}
            >
              <BarChart
                height={300}
                series={[
                  { data: presentCounts, label: "Present", id: "pvId" },
                  { data: absentCounts, label: "Absent", id: "uvId" },
                ]}
                xAxis={[
                  {
                    data: xLabels.map((item) => item.label),
                    scaleType: "band",
                    tickLabelStyle: {
                      angle: -40,
                      textAnchor: "end",
                      fontSize: 8,
                      fontWeight: "bold",
                    },
                  },
                ]}
                yAxis={[
                  {
                    tickLabelStyle: {
                      fontSize: 12,
                    },
                  },
                ]}
                leftAxis={{
                  label: "Number ofEmployees",
                  labelStyle: {
                    fontSize: "15",
                  },
                }}
              />
            </Card>
          </div>
          <Card
            className="md:!w-2/5 !w-full shadow-lg"
            title="Daily Employees Status"
            style={{
              marginTop: "50px",
              textAlign: "center",
            }}
          >
            <div className="employee-status-content flex flex-col items-center">
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: presentEmployees, label: "Present" },
                      { id: 2, value: absentEmployees, label: "Absent" },
                    ],
                  },
                ]}
                // width={400}
                height={200}
              />

              <p className="mt-4 flex justify-center items-center">
                <Typography className="font-bold">
                  {new Date().toLocaleDateString()}
                </Typography>
              </p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
