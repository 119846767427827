import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import type { UploadProps } from "antd";

const { Dragger } = Upload;

interface AntDesignDraggerProps {
  uploadUrl: string;
  // acceptedFiles?: string[];
  maxFilesize?: number;
  onDrop?: (fileUrls: string[]) => void;
}

const AntDesignDragger: React.FC<AntDesignDraggerProps> = ({
  uploadUrl,
  // acceptedFiles = [
  //   ".pdf",
  //   ".doc",
  //   ".docx",
  //   ".png",
  //   ".jpg",
  //   ".jpeg",
  //   ".csv",
  //   ".xlsx",
  //   ".xls",
  // ],
  maxFilesize = 10,
  onDrop,
}) => {
  const acceptedFiles = [
    ".pdf",
    ".doc",
    ".docx",
    ".png",
    ".jpg",
    ".jpeg",
    ".csv",
    ".xlsx",
    ".xls",
  ];
  const props: UploadProps = {
    name: "files",
    multiple: true,
    action: uploadUrl,
    beforeUpload(file) {
      const isAccepted = acceptedFiles.some((ext) =>
        file.name.toLowerCase().endsWith(ext)
      );
      if (!isAccepted) {
        message.error(`${file.name} is not a supported file type.`);
        return Upload.LIST_IGNORE;
      }
      const isWithinSize = file.size / 1024 / 1024 < maxFilesize;
      if (!isWithinSize) {
        message.error(`${file.name} exceeds the size limit.`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (onDrop && info.file.response && info.file.response.uploads) {
          const fileUrls = info.file.response.uploads.map(
            (file: any) => file.url
          );
          onDrop(fileUrls);
        }
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text " style={{ color: "rgb(165, 165, 165)" }}>
        Drag 'n' drop some files here, or click to select files
      </p>
    </Dragger>
  );
};

export default AntDesignDragger;